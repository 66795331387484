<template>
  <div class="content-page" v-if="currentRoute">
    <stage :title="currentRoute.title.rendered"/>
    <introduction
        fontWeight="normal"
        :maintext="currentRoute.content.rendered"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions} from 'vuex'
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'


export default {
  name: 'contentPage',
  components: {
    Stage,
    Introduction
  },
  computed: {
    ...mapGetters([
        'getFetched'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.name];
      return getFetched;
    },
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPage',
    ]),
  }
}
</script>
<style lang="scss">
.content-page {
  &__markdown {
    font-size: 18px;
    @media (min-width: 768px) {
      padding: 0 1.6rem 0 0;
    }
    @media (min-width: 1140px) {
      padding: 0 30px 0 100px;
    }
    h1, h2, h3, h4 {
      font-family:"neue-plak";
      font-weight: 500;
      color: #182952;
      &:not(:first-child) {
        margin-top: 65px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.content-page {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__markdown, &__contact {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__contact {
    max-width: 442px;
    font-size: 18px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 300px;
    margin: 1.6rem 0;
    @media (min-width: 1024px) {
      margin: 65px 0;
    }
  }
}
</style>
