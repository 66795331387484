import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Publikationen from '../views/publikationen.vue'
import PublikationenDetail from '../views/publikationen-detail.vue'
import ContentPage from '../views/content-page.vue'
import ThemaDetail from '../views/thema-detail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/projekt',
    name: 'projekt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mission" */ '../views/projekt.vue')
  },
  {
    path: '/beirat-fachforum',
    name: 'beirat-fachforum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "beirat-fellows" */ '../views/beirat-fellows.vue'),
  },
  {
    path: '/advisory-board-and-expert-panel',
    name: 'advisory-board-and-expert-panel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "beirat-fellows" */ '../views/beirat-fellows.vue'),
  },
  {
    path: '/diskussionspapiere',
    name: 'diskussionspapiere',
    component: Publikationen,
  },
  {
    path: '/discussion-paper',
    name: 'discussion-paper',
    component: Publikationen,
  },
  {
    path: '/diskussionspapiere/:name',
    name: 'diskussionspapiere-detail',
    component: PublikationenDetail,
  },
  {
    path: '/discussion-paper/:name',
    name: 'discussion-papers-detail',
    component: PublikationenDetail,
  },
  {
    path: '/factsheets',
    name: 'factsheets',
    component: Publikationen,
  },
  {
    path: '/factsheets/:name',
    name: 'factsheets-detail',
    component: PublikationenDetail,
  },
  {
    path: '/pressespiegel',
    name: 'pressespiegel',
    component: Publikationen,
  },
  {
    path: '/press-review',
    name: 'press-review',
    component: Publikationen,
  },
  {
    path: '/pressespiegel/:name',
    name: 'pressespiegel-detail',
    component: PublikationenDetail,
  },
  {
    path: '/press-review/:name',
    name: 'press-review-detail',
    component: PublikationenDetail,
  },
  {
    path: '/methodenheft',
    name: 'methodenheft',
    component: Publikationen,
  },
  {
    path: '/methods',
    name: 'methods',
    component: Publikationen,
  },
  {
    path: '/methodenheft/:name',
    name: 'methodenheft-detail',
    component: PublikationenDetail,
  },
  {
    path: '/methods/:name',
    name: 'methods-detail',
    component: PublikationenDetail,
  },
  {
    path: '/veranstaltungen',
    name: 'veranstaltungen',
    component: Publikationen,
  },
  {
    path: '/veranstaltungen/:name',
    name: 'veranstaltungen-detail',
    component: PublikationenDetail,
  },
  {
    path: '/events',
    name: 'events',
    component: Publikationen,
  },
  {
    path: '/events/:name',
    name: 'events-detail',
    component: PublikationenDetail,
  },
  {
    path: '/publikationen/:name',
    name: 'methodenheft-detail',
    component: PublikationenDetail,
  },
  {
    path: '/publications/:name',
    name: 'methods-booklet-detail',
    component: PublikationenDetail,
  },
  {
    path: '/projektteam',
    name: 'projektteam',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "team" */ '../views/team.vue'),
  },
  {
    path: '/projektteam/:name',
    name: 'team-detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "team-detail" */ '../views/team-detail.vue'),
  },
  {
    path: '/leistungen/:name',
    name: 'leistungen-detail',
    component: ThemaDetail
  },
  {
    path: '/services/:name',
    name: 'services-detail',
    component: ThemaDetail
  },
  {
    path: '/themen/:name',
    name: 'thema-detail',
    component: ThemaDetail
  },
  {
    path: '/topics/:name',
    name: 'topic-detail',
    component: ThemaDetail
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ContentPage,
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: ContentPage,
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: ContentPage,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: ContentPage,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
