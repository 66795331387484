<template>
    <div class="introduction">
        <div class="introduction__text">
            <label v-if="subline && maintext && fontWeight === 'bold'">{{ subline }}</label>
            <h2 v-if="maintext && fontWeight === 'bold'" :class="{ 'big': !contact && !image }" v-html="maintext" />
            <h3 v-if="maintext && fontWeight !== 'bold' && subline" v-html="subline" />
            <vue-markdown v-if="maintext && fontWeight !== 'bold'" class="introduction__markdown" :source="maintext" />
            <link-button v-if="download" class="introduction__download-button" :type="'link'" :link="download.url">Alle
                Herunterladen</link-button>
        </div>
        <div v-if="hasContact" class="introduction__contact">
            <h3>Kontakt</h3>
            <div class="introduction__contact-content">
                <div class="introduction__contact-content-details">
                    <div>
                        {{ contact.name }}
                    </div>
                    <div v-html="contact.position" />
                    <div>
                        {{ contact.phone }}
                    </div>
                </div>
                <link-button v-if="contact.contact.title" class="introduction__contact-button" :type="'link'"
                    :link="contact.contact.url">{{ contact.contact.title }}</link-button>
                <link-button v-if="contact.person.title" class="introduction__contact-button" :type="'link'"
                    :internal="true" :link="contact.person.url">{{ contact.person.title }}</link-button>
            </div>
        </div>
        <div v-if="image" class="introduction__image-wrapper">
            <img class="introduction__image" :src="image.url" :alt="image.name" />
        </div>
    </div>
</template>
<script>
import linkButton from './link-button.vue'
import VueMarkdown from 'vue-markdown-v2'
export default {
    name: 'Introduction',
    components: {
        linkButton,
        VueMarkdown
    },
    props: {
        subline: {
            type: String,
        },
        maintext: {
            type: String,
        },
        image: {
            default: () => { },
        },
        fontWeight: {
            type: String,
            default: 'bold'
        },
        contact: {
            type: Object,
            default: () => { },
        },
        download: {
            type: Object,
            default: () => { },
        },
    },
    computed: {
        hasContact() {
            if (this.contact) {
                const values = Object.values(this.contact);
                const valuesFind = values.find(val => !!val);
                return !!valuesFind;
            }
            return false;
        }
    }
}
</script>
<style lang="scss">
@import "~@/scss/markdown";

.introduction {
    &__markdown {
        @include markdown;
    }
}
</style>
<style lang="scss" scoped>
.introduction {
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap-reverse;
    margin: 0 auto 1.6rem;
    align-items: flex-end;

    @media (min-width: 1024px) {
        margin: 0 auto 150px;
        min-height: 320px;
    }

    &__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        color: #182952;
        padding: .8rem;
        min-width: 300px;

        @media (min-width: 1024px) {
            padding: 65px 65px 0 110px;
        }

        h2 {
            font-size: 25px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 500;
            max-width: 540px;
            margin: 0;

            &.big {
                max-width: 720px;
            }

            @media (min-width: 1024px) {
                font-size: 32px;
                letter-spacing: .1px;
            }
        }

        h3 {
            margin: 0 0 .6rem;
            font-size: 24px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 500;
            max-width: 80%;
        }

        div {
            font-size: 18px;
        }

        label {
            font-size: 16px;
            font-family: "freight-text-pro";
            font-weight: bold;
            margin-bottom: 1rem;

            @media (min-width: 1024px) {
                font-size: 18px;
            }
        }
    }

    &__contact {
        color: #182952;
        padding: .8rem;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 190px;

        @media (min-width: 1024px) {
            padding: 65px 0 0 0;
        }

        h3 {
            margin-top: 0;
        }
    }

    &__contact-content-details {
        margin-bottom: 1.6rem;
        font-size: 18px;
        line-height: 1.5;
        hyphens: auto;
    }

    &__download-button {
        margin-top: 2rem;
    }

    &__image-wrapper {
        display: none;
        flex: 1;
        width: 100%;
        max-width: 100%;

        @media (min-width: 1024px) {
            display: flex;
            max-width: 255px;
            height: 320px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__markdown {
        max-width: 445px;
    }
}
</style>