<template>
    <div class="stage">
        <div class="stage__text">
            <h1>
                {{text}}
            </h1>
            <span class="stage__anchors">
                <router-link to="/projektteam">
                    {{getLang === 'de' ? 'Wer wir sind' : 'Who we are'}}
                </router-link>
                <a href="#was-wir-tun" @click.prevent="$emit('clicked')">
                    {{getLang === 'de' ? 'Was wir tun' : 'What we do'}}
                </a>
            </span>
        </div>
        <div class="stage__image-wrapper">
            <img class="stage__image" :alt="image.alt || image.name" :src="image.url"/>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'StageHome',
    props: {
        image: {
            type: Object,
            default: () => {},
        },
        text: {
            type: String,
            required: true,
        }
    },
    computed: {
        ...mapGetters([
            'getLang',
        ])
    }
}
</script>
<style lang="scss" scoped>
.stage {
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap-reverse;
    margin: auto;
    @media (min-width: 1024px) {
        height: 600px;
    }
    &__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #0c3818;
        color: #ffffff;
        padding: 1.6rem;
        @media (min-width: 1024px) {
            padding: 120px 50px 0 110px;
        }
        h1 {
            font-size: 30px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 700;
            margin: 0 0 1.6rem;
            @media (min-width: 1024px) {
                font-size: 56px;
                letter-spacing: .1px;
                margin: 0 0 50px;
            }
        }
    }
    &__image-wrapper {
        display: flex;
        flex: 1;
        max-width: 472px;
        min-width: 320px;
        width: 100%;
        max-height: 320px;
        @media (min-width: 1024px) {
            max-height: 100%;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__anchors {
        font-family:"freight-text-pro";
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        a {
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
            &:last-child {
                margin-left: 50px;
            }
        }
    }
}
</style>