<template>
  <div v-if="currentRoute" class="publication">
    <stage :stageLabel="currentCat.name || ''" class="publication__stage-auto-height" :big="currentRoute.title.rendered.length < 25" :title="currentRoute.title.rendered"/>
    <div class="publication__content">
        <div class="publication__section-bg">
            <introduction
                :subline="'Beschreibung'"
                :maintext="currentRoute.acf.description"
                :timeframe="currentRoute.acf.duration"
                :next="currentRoute.acf.addLinks || []"
                :downloadLink="currentRoute.acf.downloadLink.url"
            />
            <h3 v-if="currentRoute.acf.authors" class="publication__sectionhead publication__sectionhead--left">Autor*innen</h3>
            <team v-if="currentRoute.acf.authors" :members="currentRoute.acf.authors"/>
        </div>
    </div>
    <tag-posts header="Weitere Informationen" :posts="tagPosts" :route="$route.params.name"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/project-intro.vue'
import Team from '@/components/project-team.vue'
import TagPosts from '@/components/tag-posts.vue'
import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'publikationDetail',
  components: {
    Stage,
    Introduction,
    Team,
    TagPosts
  },
  computed: {
    ...mapGetters([
        'getFetched',
        'getCats'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.params.name];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    },
    currentCat() {
        return this.getCats.filter(cat => cat.id === this.currentRoute.categories[0])[0];
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPost(this.$route.params.name);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPost',
    ]),
  }
}
</script>
<style lang="scss">
.publication {
    &__stage-auto-height {
        height: auto !important;
        @media (min-width: 1024px) {
            min-height: 442px;
        }
    }
}
</style>
<style lang="scss" scoped>
.publication {
    margin-top: 86px;
    padding: 0 .8rem;
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
    margin-bottom: 150px;
    }
    &__content {
        max-width: 1140px;
        margin: auto;
        overflow: hidden;
        @media (min-width: 768px) {
           padding: 0 1.6rem;
        }
        @media (min-width: 1024px) {
            padding: 0;
        }
    }
    &__sectionhead {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family:"neue-plak";
        font-weight: 500;
        color: #182952;
        margin: 0 0 0 1.6rem;
        &--left {
            justify-content: flex-start;
        }
        @media (min-width: 768px) {
            margin: 0;
        }
        @media (min-width: 1024px) {
            margin: 0 0 0 110px;
        }
    }
    &__partner-list {
        padding: 0;
        margin: 0;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        @media (min-width: 1140px) {
            padding: 0 110px;
        }
    }
    &__partner-list-item {
        margin: 0;
        list-style-type: none;
        padding: .8rem;
        h3 {
            margin: 0;
        }
    }
    &__section-bg {
        padding:30px 0;
        background-color: #e8edf8;
        position: relative;
        margin-bottom: 65px;
        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            left: calc(50% - 50vw);
            top: 0;
            z-index: -1;
            height: 100%;
            background-color: #e8edf8;
        }
    }
}
</style>
