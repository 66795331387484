<template>
    <transition name="drop-fade">
        <div v-if="!accepted" class="cookie-banner">
            <div class="cookie-banner__wrapper">
                <h4>Cookies und Analyse</h4>
                <p>
                    Wir verwenden Cookies, um die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
                </p>
                <button class="cookie-banner__button" @click="acceptConditions">
                    Zustimmen
                </button>
                <button class="cookie-banner__button cookie-banner__button--secondary" @click="declineConditions">
                    Ablehnen
                </button>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "CookieBanner",
    data() {
        return {
            accepted: true,
        }
    },
    methods: {
        // acceptConditions() {
        //     bootstrap().then(() => {
        //         this.accepted = true;
        //     })
        // },
        // declineConditions() {
        //     this.accepted = true;
        // }
    }
}
</script>
<style lang="scss" scoped>
.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    left: 0;
    background: #ffffff;
    padding: 1rem 1.6rem 2rem;
    z-index: 10;
    box-shadow: 0 -1px 10px 0px rgb(0 0 0 / 15%);

    &__wrapper {
        max-width: 1440px;
        width: 100%;
        margin: auto;
    }
    &__button {
        cursor: pointer;
        margin:  1rem auto 0;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        background-color: #0c3818;
        border-radius: 20px;
        color: #ffffff;
        padding: 8px 24px;
        transition: all 300ms;
        font-family: 'freight-text-pro';
        font-weight: 700;
        outline: none;
        border: none;
        margin-right: .8rem;
        &:hover {
            background-color: #5e6534;
        }
        &--secondary {
            background-color: #e2e2e2;
            color: #666666;
            &:hover {
                background-color: #d2d2d2;
            }
        }

    }

    h4 {
        font-family: 'neue-plak';
        font-size: 18px;
        font-weight: 700;
        color: #182952;
    }
    p {
        font-family: 'freight-text-pro';
        font-size: 16px;
        font-weight: 500;
        color: #182952;
    }
}
</style>