<template>
  <div id="app">
    <Header/>
    <div class="app__content">
      <transition name="drop-fade" mode="out-in" @before-enter="scrollToTop">
        <router-view :key="$route.params.name || $route.name" />
      </transition>
    </div>
    <cookie-banner/>
    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import CookieBanner from '@/components/cookie-banner.vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    Header,
    Footer,
    CookieBanner,
  },
  computed: {
    ...mapGetters([
      'getPageName',
    ]),
    routeName() {
      return this.$route.params.name || this.$route.name
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL || window.location.origin;
    },
  },
  metaInfo() {
    return {
      title: String(this.routeName).toUpperCase(),
      titleTemplate: `%s | ${this.getPageName}`
    }
  },
  created() {
    this.setUrlOrigin(this.baseUrl);
    this.fetchPageName();
    this.fetchCats();
  },
  methods: {
    ...mapMutations([
      'setUrlOrigin',
    ]),
    ...mapActions([
      'fetchCats',
      'fetchPageName',
    ]),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}
html, body {
  max-width: 1440px;
  margin: auto;
}
#app {
  font-family: 'freight-text-pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

// Fonts

@import url("https://p.typekit.net/p.css?s=1&k=lhc5epx&ht=tk&f=13466.13467.13470.13471&a=5455862&app=typekit&e=css");

@font-face {
font-family:"freight-text-pro";
src:url("https://use.typekit.net/af/04ec74/00000000000000000001205b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/04ec74/00000000000000000001205b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/04ec74/00000000000000000001205b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"freight-text-pro";
src:url("https://use.typekit.net/af/8cf83d/00000000000000000001205c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/8cf83d/00000000000000000001205c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/8cf83d/00000000000000000001205c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"freight-text-pro";
src:url("https://use.typekit.net/af/4b422b/000000000000000077359fbd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/4b422b/000000000000000077359fbd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/4b422b/000000000000000077359fbd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}

@font-face {
font-family:"freight-text-pro";
src:url("https://use.typekit.net/af/0dd853/000000000000000077359fc0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/0dd853/000000000000000077359fc0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/0dd853/000000000000000077359fc0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:500;
}

@font-face {
font-family:"neue-plak";
src:url("./assets/fonts/neueplak-black.woff") format("woff"),url("./assets/fonts/neueplak-black.woff2") format("woff2");
font-weight: 500;
}

@font-face {
font-family:"neue-plak";
src:url("./assets/fonts/neueplak-wide-black.woff") format("woff"),url("./assets/fonts/neueplak-wide-black.woff2") format("woff2");
font-weight: 700;
}

// Transitions

.drop-fade-enter-active, .drop-fade-leave-active {
  transition: all .5s;
}
.drop-fade-enter, .drop-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translate(0, -10px);
}

.app {
  &__content {
    min-height: 100vh;
  }
}

</style>
