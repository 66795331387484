<template>
    <div class="inline-blocks" :class="{'inline-blocks--width-margin': withMargin}">
        <ul class="inline-blocks__list">
            <li
                v-for="(block, i) in blocks"
                :key="i"
                class="inline-blocks__list-item"
            >
                <div class="inline-blocks__icon-wrapper" :class="service ? 'inline-blocks__icon-wrapper--services' : ''">
                    <component class="inline-blocks__icon" :is="block.icon"/>
                </div>
                <div class="inline-blocks__text">
                    <div class="inline-blocks__title">
                        {{block.title}}    
                    </div>
                    <p v-html="block.text || block.description"/>
                </div>
                <link-button v-if="block.link" :internal="true" class="inline-blocks__link" :type="'link'" :link="block.link.href || block.link.url">
                    {{block.link.text || block.link.title}}
                </link-button>
            </li>
        </ul>
    </div>
</template>
<script>
import LinkButton from '@/components/link-button.vue';

export default {
    name: 'inline-blocks',
    components: {
        IconForschung: () => import("@/components/icons/icon-forschung.vue"),
        IconPublikationen: () => import("@/components/icons/icon-publikationen.vue"),
        IconOrga: () => import("@/components/icons/icon-organisationsberatung.vue"),
        IconBildung: () => import("@/components/icons/icon-bildungsarbeit.vue"),
        IconStrukturwandel: () => import("@/components/icons/icon-strukturwandel.vue"),
        IconKlima: () => import("@/components/icons/icon-klima.vue"),
        IconWachstum: () => import("@/components/icons/icon-wachstum.vue"),
        IconNachhaltigeoe: () => import("@/components/icons/icon-nachhaltigeoe.vue"),
        IconFuehrung: () => import("@/components/icons/icon-fuehrung.vue"),
        IconAgile: () => import("@/components/icons/icon-agile.vue"),
        IconZusammen: () => import("@/components/icons/icon-zusammen.vue"),
        IconDigiarbeit: () => import("@/components/icons/icon-digiarbeit.vue"),
        IconVision: () => import("@/components/icons/icon-vision.vue"),
        IconPersonen: () => import("@/components/icons/icon-personen.vue"),
        IconThemen: () => import("@/components/icons/icon-themen.vue"),
        IconOrgstruktur: () => import("@/components/icons/icon-orgstruktur.vue"),
        IconJustTransition: () => import("@/components/icons/icon-just-transition.vue"),
        IconResilent: () => import("@/components/icons/icon-resilent.vue"),
        IconDonut: () => import("@/components/icons/icon-donut.vue"),
        IconSuffizienz: () => import("@/components/icons/icon-suffizienz.vue"),
        IconPostwachstum: () => import("@/components/icons/icon-postwachstum.vue"),
        IconZeitwohlstand: () => import("@/components/icons/icon-zeitwohlstand.vue"),
        IconAltersvorsorge: () => import("@/components/icons/icon-altersvorsorge.vue"),
        IconGewerkschaft: () => import("@/components/icons/icon-gewerkschaft.vue"),
        IconNahbarkeit: () => import("@/components/icons/icon-nahbarkeit.vue"),
        IconProgressiv: () => import("@/components/icons/icon-progressiv.vue"),
        IconLeidenschaft: () => import("@/components/icons/icon-leidenschaft.vue"),
        IconEmpowerment: () => import("@/components/icons/icon-empowerment.vue"),
        IconProjekte: () => import("@/components/icons/icon-projekte.vue"),
        IconGemeinschaft: () => import("@/components/icons/icon-gemeinschaft.vue"),
        LinkButton,
    },
    props: {
        blocks: {
            type: Array,
            required: true,
        },
        withMargin: {
            type: Boolean,
            default: true,
        },
        service: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
<style lang="scss" scoped>
.inline-blocks {
    font-size: 18px;
    color: #000000;
    &--with-margin {
        @media (min-width:1024px) {
        margin-bottom: 150px;
    }
    }
    &__list{
        display: flex;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: calc(65px - 30px);
        @media (min-width:1140px) {
            // justify-content: flex-start;
            margin-bottom: calc(100px - 30px);
        }
    }
    &__list-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0 30px 30px 0;
        padding: 0;
        list-style-type: none;
        min-width: 250px;
        max-width: 350px;
        
        &:last-child {
            margin: 0;
        }
        @media (min-width: 1140px) {
            min-width: 250px;
            max-width: 255px;
        }
    }
    &__icon-wrapper {
        width: 60px;
        height: 60px;
        margin-bottom: 1.6rem;
        &--services {
            width: 80px;
            height: 80px;
        }
    }
    &__icon {
        width: 100%;
        height: 100%;
        color: #182952;
    }
    &__title {
        font-weight: bold;
        color: #182952;
        max-width: 80%;
    }
    &__link {
        color: #f3972b;
        font-style: italic;
        text-decoration: none;
        font-weight: 700;
        &:hover {
            text-decoration: underline;
        }
    }
    &__text {
        p {
            line-height: 1.5;
            hyphens: auto;
        }
    }
}
</style>