<template>
  <div class="home" v-if="currentRoute">
    <stage-home :image="currentRoute.acf.stage.image" :text="currentRoute.acf.stage.title" @clicked="scrollTo($refs.whatWeDo)"/>
    <introduction :image="currentRoute.acf.description.image" subline="Mission" :maintext="currentRoute.acf.description.text"/>
    <div class="home__content">
      <h3 v-if="currentRoute.acf.projektPartner" class="home__sectionhead">
        {{getLang === 'de' ? 'Projektpartner' : 'Project partners'}}
      </h3>
      <references v-if="currentRoute.acf.projektPartner" :references="currentRoute.acf.projektPartner"/>
      <h2 ref="whatWeDo" class="home__sectionhead">{{currentRoute.acf.what}}</h2>
      <inline-blocks :blocks="currentRoute.acf.blocks"/>
      <fact-testimonial
        v-if="Object.keys(currentRoute.acf.fact).length"
        :image="currentRoute.acf.fact.image.url"
        :alt="currentRoute.acf.fact.image.alt || currentRoute.acf.fact.image.name"
        :quote="currentRoute.acf.fact.quote"
        :author="currentRoute.acf.fact.author"
        :quoteId="1"
        :link="currentRoute.acf.fact.link"
        :color-theme="currentRoute.acf.fact.colorTheme"
        :reversed="currentRoute.acf.fact.reversed" 
      />
      <h3 v-if="currentRoute.acf.praxisPartner" class="home__sectionhead">
        {{getLang === 'de' ? 'Praxispartner' : 'Practice partners'}}
      </h3>
      <references v-if="currentRoute.acf.praxisPartner" :references="currentRoute.acf.praxisPartner"/>
      <fact-testimonial
        v-if="Object.keys(currentRoute.acf.fact_2).length"
        :image="currentRoute.acf.fact_2.image.url"
        :alt="currentRoute.acf.fact_2.image.alt || currentRoute.acf.fact_2.image.name"
        :quote="currentRoute.acf.fact_2.quote"
        :author="currentRoute.acf.fact_2.author"
        :quoteId="2"
        :fact="currentRoute.acf.fact_2.fact"
        :color-theme="currentRoute.acf.fact_2.colorTheme"
        :reversed="currentRoute.acf.fact_2.reversed" 
      />
      <h3 v-if="currentRoute.acf.more" class="home__sectionhead">{{currentRoute.acf.more}}</h3>
      <inline-blocks v-if="currentRoute.acf.blocks_2" :blocks="currentRoute.acf.blocks_2"/>
      <fact-testimonial
        v-if="Object.keys(currentRoute.acf.fact_3).length"
        :image="currentRoute.acf.fact_3.image.url"
        :alt="currentRoute.acf.fact_3.image.alt || currentRoute.acf.fact_3.image.name"
        :quote="currentRoute.acf.fact_3.quote"
        :author="currentRoute.acf.fact_3.author"
        :quoteId="3"
        :fact="currentRoute.acf.fact_3.fact"
        :color-theme="currentRoute.acf.fact_3.colorTheme"
        :reversed="currentRoute.acf.fact_3.reversed" 
      />
      <kontakt
        v-if="Object.keys(currentRoute.acf.contact).length"
        :text="currentRoute.acf.contact.text"
        :link="currentRoute.acf.contact.link"
      />
      <tag-posts v-if="tagPosts" class="home__posts" header="Aktuelles" :posts="tagPosts" :route="$route.name"/>
      <fact-testimonial
        v-if="Object.keys(currentRoute.acf.fact_4).length"
        :image="currentRoute.acf.fact_4.image.url"
        :alt="currentRoute.acf.fact_4.image.alt || currentRoute.acf.fact_4.image.name"
        :quote="currentRoute.acf.fact_4.quote"
        :author="currentRoute.acf.fact_4.author"
        :quoteId="4"
        :fact="currentRoute.acf.fact_4.fact"
        :color-theme="currentRoute.acf.fact_4.colorTheme"
        :reversed="currentRoute.acf.fact_4.reversed" 
      />
      <h3 v-if="currentRoute.acf.references" class="home__sectionhead">
        {{getLang === 'de' ? 'Projektförderer' : 'Project sponsors'}}
      </h3>
      <references v-if="currentRoute.acf.references" :references="currentRoute.acf.references"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions} from 'vuex'
import StageHome from '@/components/stage-home.vue'
import Introduction from '@/components/introduction.vue'
import InlineBlocks from '@/components/inline-blocks.vue'
import FactTestimonial from '@/components/fact-testimonial.vue'
import References from '@/components/references.vue'
import Kontakt from '@/components/kontakt.vue'
import TagPosts from '@/components/tag-posts.vue'

export default {
  name: 'Home',
  components: {
    StageHome,
    Introduction,
    InlineBlocks,
    FactTestimonial,
    References,
    Kontakt,
    TagPosts
  },
  computed: {
    ...mapGetters([
        'getFetched',
        'getLang',
    ]),
    currentRoute(){
      const getFetched =  this.getFetched['home'];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPage('home');
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPage',
    ]),
    scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 100,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    text-align: center;
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__posts {
    margin-bottom: 65px;
  }
  &__note {
    font-size: 15px;
    font-family: 'freight-text-pro';
    margin-left: .4rem;
    color: #666666;
    text-decoration: none;
  }
}
</style>
