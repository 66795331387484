<template>
    <div class="jobs">
        <transition-group class="jobs__list" name="drop-fade" tag="ul">
            <li
                class="jobs__list-item"
                v-for="(item, i) in items"
                :key="`item-${i}`"
            >
                <label
                    v-if="item.acf.facts && item.acf.facts.location && item.acf.facts.start"
                    class="jobs__category"
                >
                    {{item.acf.facts.location}} 
                    | 
                    {{item.acf.facts.start}} 
                    | 
                    <span
                        v-for="(tag, i) in item.tags"
                        :key="i"
                    >
                        {{`${getTagName(tag)}${i + 1 === item.tags.length ? '' : ', '}`}}
                    </span>
                </label>
                <h3 v-html="item.title.rendered" />
                <label v-if="item.acf.facts && item.acf.facts.workinghours" class="jobs__workinghours">
                    {{item.acf.facts.workinghours}} / (w/ m/ d)
                </label>
                <div class="jobs__excerpt" v-html="item.excerpt.rendered"/>
                <link-button class="jobs__button" :internal="true" :link="`${$route.path}/${item.slug}`">Mehr Erfahren</link-button>
            </li>
        </transition-group>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import linkButton from './link-button.vue'
export default {
  components: { linkButton },
    name: 'jobs',
    props: {
        items: {
            type: Array,
            default: () => [],
        }
    },
    computed: {
        ...mapGetters([
            'getTags'
        ]),
        tagIds(){
            const ids = [];
            this.items.map(item => {
                item.tags.map(tag => {
                    ids.push(tag);
                });
            })
            return ids;
        }
    },
    created() {
        if(this.tagsInItems()) {
            this.tagIds.map(id => {
                this.fetchTags(id);
            })
        }
    },
    methods: {
        ...mapActions([
            'fetchTags'
        ]),
        tagsInItems() {
            const tagsInItems = this.items.filter(item => item.tags.length);
            if(tagsInItems.length > 0) {
                return true;
            }
            return false;
        },
        getTagName(val) {
            if(this.getTags.length) {
                const filter = this.getTags.filter(item => item.id === val)[0].name;
                return filter;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.jobs {
    &__list, &__list-item {
        padding: 0;
        margin: 0;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 1.6rem;
        margin-bottom: 1.6rem;
        @media (min-width: 1024px) {
            grid-template-columns: repeat(auto-fill, minmax(300px, calc(50% - 15px)));
            grid-column-gap: 30px;
            grid-row-gap: 60px;
            margin-bottom: 150px;
        }
    }
    &__list-item {
        list-style-type: none;
        padding: 30px;
        box-shadow: 0 2px 24px 0 rgba(0,0,0,.1);
        display: flex;
        flex-direction: column;
        @media (min-width: 1024px) {
            min-height: 335px;
        }
        h3 {
            color: #182952;
            font-size: 20px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 500;
            margin: .8rem 0 0 0;
            @media (min-width: 1024px) {
                margin: 24px 0 0 0;
                font-size: 25px;
                letter-spacing: .1px;
            }
        }
    }
    &__excerpt {
        margin-bottom: 50px;
        line-height: 1.5;
        flex: 1;
        font-size: 18px;
    }
    &__category {
        font-size: 16px;
    }
    &__workinghours {
        font-weight: 700;
        font-size: 18px;
    }
    &__button {
        margin: 0 auto 0 0
    }
}
</style>