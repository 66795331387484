<template>
    <div class="newsletter">
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <form action="https://nexteconomylab.us7.list-manage.com/subscribe/post?u=837a219034fbcba63ca679876&amp;id=5c38177749" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_837a219034fbcba63ca679876_5c38177749" tabindex="-1" value=""></div>
            <div class="newsletter__input-wrapper">
                <input type="email" value="" name="EMAIL" class="email" id="EMAIL" :placeholder="getLang === 'de' ? 'Ihre E-Mail-Adresse' : 'Your e-mail adress'" required>
                <!-- <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"> -->
                <button class="newsletter__send">
                    <icon-send/>
                </button>
            </div>
            <div class="newsletter__label-wrapper">
                <label for="EMAIL">
                    {{getLang === 'de' ? 'Melden Sie sich zu unserem Newsletter an und bleiben Sie informiert.' : 'Subscribe to our newsletter and stay informed.'}}
                </label>
            </div>
        </form>
    </div>
</template>
<script>
import IconSend from '@/components/icons/icon-send.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'Newsletter',
    components: {
        IconSend,
    },
    computed: {
        ...mapGetters([
            'getLang',
        ]),
    }
}
</script>
<style lang="scss" scoped>
.newsletter {
    input[type=email] {
        width: 100%;
        padding: .5rem .8rem;
        border: 0;
        outline: 0;
        -webkit-appearance: none;
        background-color: transparent;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
    }
    &__input-wrapper {
        background-color: #fef4ee;
        position: relative;
        margin-top: 1.6rem;
        width: 100%;
        max-width: 320px;
    }
    &__send {
        position: absolute;
        top: calc(50% - 10px);
        right: .8rem;
        height: 20px;
        width: 20px;
        color: #0c3818;
        border: 0;
        background-color: transparent;
        outline: 0;
        box-shadow: none;
        -webkit-appearance: none;
        cursor: pointer;
        svg {
            height: 100%;
            width: auto;
        }
    }
    &__label-wrapper {
        margin: .8rem 0;
    }
}
</style>