<template>
  <div class="publications" v-if="currentRoute">
    <stage :stageLabel="currentCat ? currentCat.name : ''" :big="true" :title="currentRoute.title.rendered" />
    <introduction fontWeight="bold" :subline="currentRoute.title.rendered" :maintext="currentRoute.acf.description"
      :download="currentRoute.acf.download" />
    <div v-if="pagedPublications.length > 0" ref="publicationslist" class="publications__content">
      <projects :items="pagedPublications" />
      <pagination v-if="dataProps.totalPages > 1" @change-current="setCurrentPage" :currentPage="currentPage"
        :totalPages="Number(dataProps.totalPages)" />
    </div>
    <div v-else ref="publicationslist" class="publications__content publications__content--empty">
      <h2>{{ getLang === 'de' ? 'Aktuell sind keine Publikationen verfügbar' : 'No publications are currently available' }}.
      </h2>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import Projects from '@/components/jobs-blocks.vue'
import { mapActions, mapGetters } from 'vuex'
import Pagination from '../components/pagination.vue'


export default {
  name: 'Publikationen',
  components: {
    Stage,
    Introduction,
    Projects,
    Pagination
  },
  data() {
    return {
      currentPage: 1,
      dataList: [],
      dataProps: {},

    }
  },
  computed: {
    ...mapGetters([
      'getFetched',
      'getCats',
      'getLang',
    ]),
    currentRoute() {
      const getFetched = this.getFetched[this.$route.name];
      return getFetched;
    },
    pagedPublications() {
      return this.dataList.slice((this.currentPage - 1) * 10, this.currentPage * 10)
    },
    currentCat() {
      return this.getCats.filter(cat => cat.slug === this.$route.name)[0]
    },
  },
  created() {
    if (!this.currentRoute) {
      this.fetchCurrentPage(this.$route.path);
    }
    if (this.dataList.length === 0 && this.getCats.length) {
      this.fetchPublications()
    }
  },
  watch: {
    getCats(cur, old) {
      if (old.length === 0) {
        this.fetchPublications()
      }
    },
    currentPage() {
      if (Number(this.dataProps.totalItems) > old * 10 && this.dataList.length !== Number(this.dataProps.totalItems)) {
        this.fetchPublications()
      }
      this.scrollTo(this.$refs.publicationslist);
    }
  },
  methods: {
    ...mapActions([
      'fetchCurrentPage',
      'fetchPostsByCat'
    ]),
    fetchPublications() {
      const payload = {
        cat: this.currentCat,
        page: this.currentPage,
      }
      this.fetchPostsByCat(payload)
        .then(() => {
          const { slug } = this.currentCat;
          this.dataList = this.$store.state[slug].list
          this.dataProps = this.$store.state[slug].props
        });
    },
    setCurrentPage(val) {
      if (val <= this.getDataProps.totalPages && val > 0) {
        this.currentPage = val
      }
    },
    scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 150,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.publications {
  margin-top: 86px;
  padding: 0 .8rem;

  &__content {
    max-width: 1140px;
    margin: auto;
    padding: 0 .8rem;

    &--empty {
      text-align: center;
      margin-bottom: 86px;
    }
  }
}
</style>
