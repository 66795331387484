<template>
    <div class="project-intro">
        <div class="project-intro__text">
            <h3 v-if="subline">{{subline}}</h3>
            <div class="project-intro__maintext" v-html="maintext"/>
        </div>
        <div class="project-intro__contact">
            <h3 v-if="timeframe">Laufzeit</h3>
            <div v-if="timeframe" class="project-intro__contact-content">
                <div class="project-intro__contact-content-details">
                    {{timeframe}}
                </div>
            </div>
            <h3 v-if="contact">Ansprechpartner</h3>
            <div v-if="contact" class="project-intro__contact-content">
                <div class="project-intro__contact-content-details">
                    <link-button :type="'link'" :internal="true" :link="contact.url">
                        {{contact.title}}
                    </link-button>
                </div>
            </div>
            <h3 v-if="next.length">Weiterführendes</h3>
            <div v-if="next.length" class="project-intro__contact-content">
                <div
                    v-for="(obj, i) in next"
                    :key="i"
                    class="project-intro__contact-content-details"
                >
                    <link-button :type="'link'" :internal="!relative(obj.addLinks_link.url)" :link="obj.addLinks_link.url">
                        {{obj.addLinks_link.title}}
                    </link-button>
                </div>
            </div>
            <div class="project-intro__share">
                <button @click="shareNow" class="project-intro__share-button">
                    <icon-link/>
                    <input type="text" class="project-intro__share-input" ref="hiddeninput" :value="clipboardLink"/>
                    <span v-if="copied">Link kopiert</span>
                    <span v-else>Teilen</span>
                </button>
                <a v-if="downloadLink" target="_blank" :href="downloadLink" class="project-intro__share-button">
                    <icon-shoppingbag/>
                    <span>Download</span>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import LinkButton from '@/components/link-button.vue';
import IconLink from '@/components/icons/icon-link.vue';
import IconShoppingbag from '@/components/icons/icon-shoppingbag.vue';
export default {
    name: 'Introduction',
    components: {
        LinkButton,
        IconLink,
        IconShoppingbag,
    },
    props: {
        subline: {
            type: String,
        },
        maintext: {
            type: String,
        },
        contact: {
            type: Object,
            default: () => {}
        },
        timeframe: {
            type: String,
            default: ''
        },
        next: {
            type: Array,
            default: () => []
        },
        downloadLink: {
            type: String,
            default: '',
        }

    },
    data() {
        return {
            copied: false,
        }
    },
    computed: {
        clipboardLink() {
            return window.location.href;
        }
    },
    methods: {
        relative(url) {
            if(url) {
                return url.includes('https://') || url.includes('http://');
            }
        },
        shareNow() {
            // Navigator.share only works with HTTPS
            if (window.navigator.share) { 
                window.navigator.share({
                    title: this.$route.name,
                    url: this.clipboardLink
                })
            } else {
                const copyText = this.$refs.hiddeninput;
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices */
                document.execCommand('copy');
                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                },1500);
            }
        }
    }
}
</script>
<style lang="scss">
.project-intro {
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap-reverse;
    margin: 0 auto;
    @media (min-width: 1024px) {
        min-height: 320px;
    }
    &__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        color: #182952;
        padding: 1.6rem;
        font-size: 18px;
        @media (min-width: 768px) {
            padding: 0 1.6rem 0 0;
        }
        @media (min-width: 1140px) {
            padding: 0 30px 0 100px;
        }
        h1, h2, h3, h4 {
            font-family:"neue-plak";
            font-weight: 500;
            color: #182952;
            margin-top: 0;
            &:not(:first-child) {
                margin-top: 65px;
            }
        }
        h3 {
            font-size: 24px;
        }
        @media (min-width: 1024px) {
            padding: 65px 65px 65px 110px;
        }
        div {
            font-size: 18px;
        }
        label {
            font-size: 16px;
            font-family:"freight-text-pro";
            font-weight: bold;
            margin-bottom: .8rem;
            @media (min-width: 1024px) {
                font-size: 18px;
                margin-bottom: 20px;;
            }
        }
    }
    &__maintext {
        margin-bottom: 1.6rem;
        line-height: 1.5;
        a {
            color: #f3972b;
            font-style: italic;
            text-decoration: none;
            font-weight: 700;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__contact {
        color: #182952;
        padding: 1.6rem;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 442px;
        @media (min-width: 1024px) {
            padding: 65px 0 0 0;
        }
        h3 {
            margin: 1.6rem 0 .4rem 0;
            &:first-child {
                margin: 0;
            }
        }
    }
    &__contact-content-details {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        a {
            color: #f3972b;
            font-style: italic;
            text-decoration: none;
            font-weight: 700;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__share {
        margin-top: 3.2rem;
    }
    &__share-button {
        display: flex;
        font-size: 18px;
        font-family: inherit;
        background-color: transparent;
        border: none;
        color: #182952;
        transition: color 300ms;
        cursor: pointer;
        padding: 0;
        margin-bottom: 1rem;
        text-decoration: none;
        &:hover {
            color: #f3972b;
            font-weight: 700;
            font-style: italic;
            text-decoration: underline;
        }
        svg {
            height: 25px;
            width: 25px;
            margin-right: .8rem;
        }
    }
    &__share-input {
        position: absolute;
        top: -100%;
        left: -100%;
        opacity: 0;
    }
}
</style>