<template>
    <div class="pagination">
        <button
            class="pagination__button"
            v-if="currentPage !== 1"
            @click="setCurrentPage(currentPage - 1)"
            aria-label="back"
        >
            <icon-right class="pagination__icon pagination__icon--prev"/>
        </button>
        <ul class="pagination__list">
            <li 
                v-for="(items, i) in totalPages"
                :key="i"
                class="pagination__list-item"
                :class="{'pagination__list-item--current': i + 1 === currentPage}"
                @click="setCurrentPage(i + 1)"
            >
                {{i + 1}}
            </li>
        </ul>
        <button
            class="pagination__button"
            v-if="currentPage !== totalPages"
            @click="setCurrentPage(currentPage + 1)"
            aria-label="next"
        >
            <icon-right class="pagination__icon pagination__icon--next"/>
        </button>
</div>
</template>
<script>
import IconRight from '@/components/icons/icon-right.vue'; 

export default {
    name: 'Pagination',
    components: {
        IconRight,
    },
    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 0,
        }
    },
    methods: {
        setCurrentPage(val) {
            this.$emit('change-current', val);
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;

    &__button {
        background: transparent;
        border: none;
        appearance: none;
        outline: none;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__icon {
        height: 20px;
        width: 20px;
        color: #182952;
        &--prev {
            transform: rotate(180deg);
        }
    }
    &__list {
        display: flex;
        padding: 0;
        margin: 0 .8rem;
    }
    &__list-item {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-right: .8rem;
        font-size: 18px;
        display: flex;
        align-items: center;
        transform: translate(0, -2px);
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &--current {
            text-decoration: underline;
            font-weight: 700;
        }
    }
}
</style>
