<template>
    <ul class="navigation" v-if="navigationItems.length" :class="view">
        <li
            v-for="item in navigationItems"
            :key="item.ID"
            class="list-item primary"
        >
            {{item.title}}
            <ul
                v-if="submenu[item.ID]"
                class="list"
            >
                <li
                    v-for="subitem in submenu[item.ID]"
                    :key="subitem.ID"
                    class="list-item"
                >
                    <router-link :to="subitem.url" v-html="subitem.title"/>
                </li>
            </ul>
        </li>
    </ul>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'Navigation',
    props: {
        view: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            submenu: {},
        }
    },
    computed: {
        ...mapGetters([
            'getNavigation'
        ]),
        navigationItems() {
            if(this.getNavigation.main) {
                return this.getSubMenu('0');
            }
            return [];
        },
    },
    watch: {
        navigationItems(val) {
            if(val.length && this.getNavigation.footer) {
                this.$nextTick(() => {
                    val.map(elm => {
                        this.$set(this.submenu, elm.ID, this.getSubMenu(`${elm.ID}`))
                    });
                })
            }
        }
    },
    created() {
        this.fetchNavigation('main');
    },
    methods: {
        ...mapActions([
            'fetchNavigation'
        ]),
        getSubMenu(id) {
            if(this.getNavigation.main) {
                return this.getNavigation.main.filter(item => item.menu_item_parent === id);
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.navigation {
    padding: .8rem 0;
    margin: 0;
    width: 100%;
    font-weight: bold;
        .list, .list-item  {
        padding: 0 0 0 .4rem;
        margin: 0;
        list-style-type: none !important;
        &.primary {
            padding: 0 0 .8rem 0;
            margin-bottom: .8rem;
            border-bottom: 1px solid #eeeeee;
        }
    }
    a {
        text-decoration: none;
        font-weight: normal;
        color: inherit;
        &:visited, &:active {
            color: inherit;
        }
        &.router-link-exact-active, &:hover {
            color: #f3972b;
        }
    }
    &.desktop {
        display: flex;
        justify-content: space-between;
        max-width: 700px;
        margin: auto;
        padding: 0;
        height: 100%;
        .primary {
            position: relative;
            margin: 0;
            padding: 0;
            border: none;
            height: 100%;
            align-items: center;
            display: flex;
            .list {
                pointer-events: none;
                opacity: 0;
                padding: .8rem;
                transition: all 300ms;
                position: absolute;
                top: 100%;
                left: 0;
                background-color: #ffffff;
                box-shadow: 0 10px 15px -7px rgba(0, 0, 0, 0.15);
                min-width: 220px;
                .list-item {
                    padding: 0;
                    margin-bottom: .8rem;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
            &:hover {
                .list {
                    pointer-events: all;
                    opacity: 1;
                }   
            }
        }
    }
}
</style>