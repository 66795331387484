<template>
    <div class="references">
        <ul class="references__list">
            <li class="references__list-item"
                v-for="(reference, i) in references"
                :key="i"
            >
                <img class="references__image" :alt="reference.alt || reference.name" :src="reference.url"/>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'references',
    props: {
        references: {
            type: Array,
            required: true
        }
    }
}
</script>
<style lang="scss" scoped>
.references {
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
        margin-bottom: 150px;
    }
    &__list {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;
    }
    &__list-item {
        display: flex;
        height: auto;
        width: auto;
        margin: 1.6rem;
        width: 170px;
        &:nth-child(n + 5) {
            @media (max-width: 600px) {
                display: none;
            }
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

}
</style>