<template>
    <div class="project-team">
        <ul class="project-team__list">
            <li
                class="project-team__list-item"
                v-for="(member, i) in members"
                :key="i"
            >
                <router-link :to="member.team_link.url ? member.team_link.url : '/team'">
                    <div class="project-team__image-wrapper">
                        <img v-if="member.team_image" :src="member.team_image.url" :alt="member.team_name"/>
                    </div>
                    <div class="project-team__name-wrapper">
                        <h3 v-html="member.team_name" />
                    </div>
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'ProjectTeam',
    props: {
        members: {
            type: Array,
            default: () => []
        }
    },
}
</script>
<style lang="scss" scoped>
.project-team {
    padding: 1.6rem;
    @media (min-width: 768px) {
        padding: 1.6rem 0;
    }
    @media (min-width: 1024px) {
        padding: 65px 0 65px 110px;
    }
    @media (min-width: 1140px) {
        max-width: calc(100% - 442px)
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 240px));
        grid-gap: 16px;
        padding: 0;
        margin: 0;
    }
    &__list-item {
        padding: 0;
        margin: 0;
        list-style-type: none;
        a {
            display: flex;
            align-items: center;
            color: inherit;
            text-decoration: none;
            color: #182952;
        }
    }
    &__image-wrapper {
        width: 95px;
        min-width: 95px;
        height: 95px;
        min-height: 95px;
        margin-right: 1.6rem;
        background-color: #ced8ee;
        border-radius: 50%;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}
</style>