import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageName: '',
    urlOrigin: '',
    logo: {},
    events: {
      props: {},
      list: [],
    },
    team: {
      props: {},
      list: [],
    },
    fetched: {},
    tags: [],
    cats: [],
    navigation: {},
  },
  getters: {
    getTeam: state => state.team.list,
    getTeamListProps: state => state.team.props,
    getFetched: state => state.fetched, // this getter is unsorted do not use in Lists
    getTags: state => state.tags,
    getCats: state => state.cats,
    getNavigation: state => state.navigation,
    getLogo: state => state.logo,
    getPageName: state => state.pageName,
    getLang: state => state.urlOrigin ===  'https://www.oekologische-gewerkschaftspolitik.de' ? 'de' : 'en',
  },
  mutations: {
    setUrlOrigin(state, url) {
      state.urlOrigin = url;
    },
    setLogo(state,logo) {
      state.logo = logo;
    },
    setCatPosts(state, payload) {
      Vue.set(state, payload.cat.slug, {list: [], props: {}});
      payload.res.data.forEach(obj => {
        state[payload.cat.slug].list.push(obj);
      })
    },
    setCatPostsProps(state, obj) {
      Vue.set(state[obj.cat.slug], 'props', obj.res);
    },
    setEvents(state, res) {
      res.forEach(obj => {
        state.events.list.push(obj);
      })
    },
    setEventsListProps(state, res) {
      state.events.props = res;
    },
    setTeam(state, res) {
      res.forEach(obj => {
        state.team.list.push(obj);
      })
    },
    setTeamListProps(state, res) {
      state.team.props = res;
    },
    setFetched(state, res) {
      Vue.set(state.fetched, res[0].slug, res[0])
    },
    setTagPosts(state, res){
      Vue.set(state.fetched[res.route], 'postTags', res.data)
    },
    setFeatureImages(state, data){
      Vue.set(state.fetched[data.route].postTags[data.index], 'featured_media', data.res)
    },
    setPostImage(state, data){
      Vue.set(state.fetched[data.route], 'featured_media', data.res)
    },
    setTags(state, res) {
      state.tags.push( res);
    },
    setCats(state, res) {
      state.cats = res;
    },
    setNavigation(state, navObj) {
      Vue.set(state.navigation, navObj[0], navObj[1]);
    },
    setPageName(state, name) {
      state.pageName = name;
    }
  },
  actions: {
    async fetchProjects({ commit }, page) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/posts?categories=3&per_page=10&page=${page}`)
        .then(res => {
          commit('setProjects', res.data);
          commit('setProjectListProps', {
            totalItems: res.headers['x-wp-total'],
            totalPages: res.headers['x-wp-totalpages'],
          })
        })
    },
    async fetchLogo({ commit }) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/routes/brand`)
        .then(res => {
          commit('setLogo', res.data);
        })
    },
    async fetchPostsByCat({ commit }, payload) {
      return new Promise ((resolve) => {
        const {page, cat} = payload
        axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/posts?categories=${cat.id}&per_page=10&page=${page}`)
          .then(res => {
            const dataObj = {
              cat,
              res,
            }
            commit(`setCatPosts`, dataObj);
            commit(`setCatPostsProps`, {
              cat,
              res: {
                totalItems: res.headers['x-wp-total'],
                totalPages: res.headers['x-wp-totalpages'],
              }
            })
            resolve()
          })
      })
    },
    async fetchTags({ commit }, id) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/tags/${id}`)
        .then(res => {
          commit('setTags', res.data);
        })
    },
    async fetchCats({ commit }) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/categories?per_page=100`)
        .then(res => {
          commit('setCats', res.data);
        })
    },
    async fetchCurrentPost({ commit, dispatch }, route) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/posts?slug=${route}`)
        .then(res => {
          if(res.data.length > 0) {
            if(res.data[0].acf.post_tags && res.data[0].acf.post_tags.length) {
              const routeAndTags = {
                route: route,
                tags: res.data[0].acf.post_tags 
              };
              dispatch('fetchPostsByTags', routeAndTags);
            }
            commit('setFetched', res.data);
          } else {
            router.push('/')
          }

        })
    },
    async fetchPostsByTags({ commit }, obj){
      const joinedTags = obj.tags.join();
      axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/posts?tags=${joinedTags}&per_page=6&page=1`)
        .then(res => {
          if(res.data.length > 0) {
            const resObj = {
              route: obj.route,
              data: res.data,
            };
            commit('setTagPosts', resObj);
          }
        });
    },
    async fetchFeatureImages({ commit }, arr) {
      arr.forEach(obj => {
        axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/media/${obj.id}`)
        .then(res => {
          if(res.data) {
            const resObj = {
              route: obj.route,
              index: obj.index,
              res: res.data,
            }
            commit('setFeatureImages', resObj);
          }
        })
      })
    },
    async fetchPostImage({ commit }, obj) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/media/${obj.id}`)
      .then(res => {
        if(res.data) {
          const resObj = {
            route: obj.route,
            res: res.data,
          }
          commit('setPostImage', resObj);
        }
      })
    },
    async fetchCurrentPage({ commit, dispatch }, route) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/wp/v2/pages?slug=${route}`)
        .then(res => {
          if(res.data.length > 0) {
            if(res.data[0].acf.post_tags && res.data[0].acf.post_tags.length) {
              const routeAndTags = {
                route: route,
                tags: res.data[0].acf.post_tags
              };
              dispatch('fetchPostsByTags', routeAndTags);
            }
            commit('setFetched', res.data);
          } else {
            // router.push('/')
          }
        })
    },
    async fetchNavigation({ commit }, menu) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json/routes/menus/${menu}`)
        .then(res => {
          if(res.data.length > 0) {
            commit('setNavigation', [menu, res.data]);
          }
        })
    },
    async fetchPageName({ commit }) {
      axios.get(`${this.state.urlOrigin}/backend/wp-json`)
        .then(res => {
          commit('setPageName', res.data.name);
        })
    }
  },
  modules: {
  }
})
